import { createStore } from 'redux';

export const toggleLoading = loading => ({
	type: 'TOGGLE_LOADING',
	loading
});

export const toggleNightMode = () => ({
	type: 'TOGGLE_NIGHT_MODE'
});

export const updateService = service => ({
	type: 'UPDATE_SERVICE',
	service
});

export const updateScrollbarScale = scale => ({
	type: 'UPDATE_SCROLLBAR_SCALE',
	scale
});

export const toggleMobileNav = () => ({
	type: 'TOGGLE_MOBILE_NAV'
});

const defaultState = {
	loading: true,
	nightMode: false,
	service: 0,
	scrollbarScale: 100,
	mobileNav: false
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'TOGGLE_LOADING':
			return Object.assign({}, state, { loading: action.loading });

		case 'TOGGLE_NIGHT_MODE':
			return Object.assign({}, state, { nightMode: !state.nightMode });

		case 'UPDATE_SERVICE':
			return Object.assign({}, state, { service: action.service });

		case 'UPDATE_SCROLLBAR_SCALE':
			return Object.assign({}, state, { scrollbarScale: action.scale });

		case 'TOGGLE_MOBILE_NAV':
			return Object.assign({}, state, { mobileNav: !state.mobileNav });

		default:
			return state;
	}
};

export default createStore(reducer);
