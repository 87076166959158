// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\nodejs\\nammu-gatsby\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("C:\\nodejs\\nammu-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clients-js": () => import("C:\\nodejs\\nammu-gatsby\\src\\pages\\clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("C:\\nodejs\\nammu-gatsby\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\nodejs\\nammu-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("C:\\nodejs\\nammu-gatsby\\src\\pages\\services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

