import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { motion, AnimatePresence, useViewportScroll } from 'framer-motion';
import { useWheel } from 'react-use-gesture';
import Toggle from 'react-toggle';

import { updateService, toggleNightMode, updateScrollbarScale } from './store'

import Nav from './Nav';
import MobileNav from './MobileNav';

import '../index.less';
import 'react-toggle/style.css';

const pages = [
	'/',
	'/services',
	'/clients',
	'/contact'
]

const variants = {
	initial: {
		opacity: 0
	},
	enter: {
		position: 'relative',
		opacity: 1,
		filter: 'blur(0px)'
	},
	blur: {
		filter: 'blur(5px)'
	},
	exit: {
		position: 'absolute',
		top: 0,
		left: 0,
		opacity: 0,
		filter: 'blur(5px)'
	}
};

const Layout = ({ service, mobileNav, nightMode, dispatch, children, location }) => {
	const [ scrollDisabled, setScrollDisabled ] = useState(false);
	const { scrollYProgress } = useViewportScroll();

	const hasScroll = () => {
		if (typeof document === 'undefined') {
			return false;
		}
		else {
			const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);
			const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

			return viewportHeight - documentHeight !== 0;
		}
	};

	const bind = useWheel(({ event, first, last }) => {
		if (first && !scrollDisabled) {
			if (location.pathname === '/services') {
				if (event.deltaY > 0) {
					let s = service + 1;

					if (s === 4)
						navigate(pages[pages.findIndex(i => i === '/services') + 1]);
					else
						dispatch(updateService(s));
				}
				else if (event.deltaY < 0) {
					let s = service - 1;

					if (s === -1)
						navigate(pages[pages.findIndex(i => i === '/services') - 1]);
					else
						dispatch(updateService(s));
				}
			}
			else {
				if (event.deltaY > 0 && scrollYProgress.current === 1 || event.deltaY > 0 && !hasScroll()) {
					let index = pages.findIndex(i => i === location.pathname);
					index += 1;

					if (index === 1)
						dispatch(updateService(0));

					if (index < pages.length) {
						dispatch(updateScrollbarScale(100));
						navigate(pages[index]);
					}
				}
				else if (event.deltaY < 0 && scrollYProgress.current === 0) {
					let index = pages.findIndex(i => i === location.pathname);
					index -= 1;

					if (index === 1)
						dispatch(updateService(3));

					if (index >= 0) {
						dispatch(updateScrollbarScale(100));
						navigate(pages[index]);
					}
				}
			}
		}

		if (last)
			setTimeout(() => setScrollDisabled(false), 300);
	}, {
		domTarget: typeof document === 'undefined' ? null : document.getElementById('___gatsby')
	});

	useEffect(() => {
		bind();
	}, [bind]);

	return (
		<div className={`layout ${nightMode ? 'night' : ''}`}>
			<div className="nightmode-toggler">
				<Toggle
					defaultChecked={nightMode}
					icons={{
						checked: null,
						unchecked: null
					}}
					onChange={() => dispatch(toggleNightMode())}
				/>
			</div>

			<Nav pathname={location.pathname} />

			<AnimatePresence exitBeforeEnter>
				<motion.main
					key={location.pathname}
					variants={variants}
					initial="initial"
					animate={mobileNav ? 'blur' : 'enter'}
					exit="exit"
					id="main"
				>
					{children}
				</motion.main>
			</AnimatePresence>

			<MobileNav pathname={location.pathname} />
		</div>
	);
};

export default connect(
	state => ({
		service: state.service,
		mobileNav: state.mobileNav,
		nightMode: state.nightMode
	})
)(Layout);
