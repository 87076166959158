module.exports = [{
      plugin: require('C:/nodejs/nammu-gatsby/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:200,400,600","Open+Sans:400,600,800"]}},
    },{
      plugin: require('C:/nodejs/nammu-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/nodejs/nammu-gatsby/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MWB4K36","includeInDevelopment":false},
    },{
      plugin: require('C:/nodejs/nammu-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
