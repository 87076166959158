import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { updateService } from './store'

const Nav = ({ service, scrollbarScale, pathname, dispatch }) => {
	const { scrollYProgress } = useViewportScroll();

	const clientScrollY = useTransform(scrollYProgress, value => {
		if (typeof document === 'undefined') {
			return 0;
		}
		else {
			const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);
			const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

			return `${value * (100 - (viewportHeight / documentHeight * 100)) * -1.6}%`;
		}
	});

	return (
		<nav className="navbar">
			<div>
				<span>
					<motion.div
						animate={{
							paddingTop: pathname === '/' ? 50 : 10,
							paddingBottom: pathname === '/' ? 50 : 10
						}}
					>
						<Link to="/"><div className="nammu-logo" /></Link>
					</motion.div>
					<motion.div
						className="line"
						animate={{
							width: pathname === '/' ? '2px' : '1px'
						}}
						transition={{
							duration: 0.2
						}}
						style={{
							marginRight: 0
						}}
					/>
				</span>
			</div>
			<div>
				<span>
					<motion.div
						animate={{
							paddingTop: pathname === '/services' ? 50 : 10,
							paddingBottom: pathname === '/services' ? 50 : 10
						}}
					>
						<Link
							to="/services"
							style={{ fontWeight: pathname === '/services' ? 600 : 400 }}
							onClick={() => dispatch(updateService(0))}
						>
							Services
						</Link>
					</motion.div>
					<div className="line" />
					{pathname === '/services' && (
						<div
							className="line"
							style={{
								height: '25%',
								marginRight: 0,
								marginTop: `${75 - service * 25}%`,
								transition: 'margin-top 0.2s ease-in-out'
							}}
						/>
					)}
				</span>
			</div>
			<div>
				<span>
					<motion.div
						animate={{
							paddingTop: pathname === '/clients' ? 50 : 10,
							paddingBottom: pathname === '/clients' ? 50 : 10
						}}
					>
						<Link to="/clients" style={{ fontWeight: pathname === '/clients' ? 600 : 400 }}>Clients</Link>
					</motion.div>
					<div className="line" />
					{pathname === '/clients' && (
						<motion.div
							className="line"
							style={{
								height: `${scrollbarScale}%`,
								marginTop: `${100 - scrollbarScale}%`,
								marginRight: 0,
								y: scrollbarScale === 100 ? 0 : clientScrollY
							}}
						/>
					)}
				</span>
			</div>
			<div>
				<span>
					<motion.div
						animate={{
							paddingTop: pathname === '/contact' ? 50 : 10,
							paddingBottom: pathname === '/contact' ? 50 : 10
						}}
					>
						<Link to="/contact" style={{ fontWeight: pathname === '/contact' ? 600 : 400 }}>Contact</Link>
					</motion.div>
					<div className="line" />
					{pathname === '/contact' && (
						<motion.div
							className="line"
							style={{
								height: `${scrollbarScale}%`,
								marginTop: `${100 - scrollbarScale}%`,
								marginRight: 0,
								y: scrollbarScale === 100 ? 0 : clientScrollY
							}}
						/>
					)}
				</span>
			</div>
		</nav>
	);
}

export default connect(
	state => ({
		service: state.service,
		scrollbarScale: state.scrollbarScale
	})
)(Nav);
