import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { AppstoreTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { toggleMobileNav, updateService } from './store'

const iconVariants = {
	hidden: {
		opacity: 0,
		scale: 0
	},
	visible: {
		opacity: 1,
		scale: 1
	}
}

const navVariants = {
	hidden: {
		opacity: 0,
		scaleY: 0
	},
	visible: {
		opacity: 1,
		scaleY: 1
	}
}

const MobileNav = ({ service, mobileNav, dispatch, pathname }) => (
	<>
		<motion.nav
			className="mobile-navbar"
			initial="hidden"
			animate={mobileNav ? 'visible' : 'hidden'}
			variants={navVariants}
		>
			<div onClick={() => dispatch(toggleMobileNav())}>
				<span>
					<div>
						<Link to="/" style={{ padding: 0 }}><div className="nammu-logo" /></Link>
					</div>
					<motion.div
						className="line"
						animate={{
							height: pathname === '/' ? '2px' : '1px'
						}}
						transition={{
							duration: 0.2
						}}
					/>
				</span>
			</div>
			<div onClick={() => dispatch(toggleMobileNav())}>
				<span>
					<div>
						<Link
							to="/services"
							style={{ fontWeight: pathname === '/services' ? 600 : 400 }}
							onClick={() => dispatch(updateService(0))}
						>
							Services
						</Link>
					</div>
					<motion.div
						className="line"
						animate={{
							height: pathname === '/services' ? '2px' : '1px'
						}}
						transition={{
							duration: 0.2
						}}
					/>
				</span>
			</div>
			<div onClick={() => dispatch(toggleMobileNav())}>
				<span>
					<div>
						<Link to="/clients" style={{ fontWeight: pathname === '/clients' ? 600 : 400 }}>Clients</Link>
					</div>
					<motion.div
						className="line"
						animate={{
							height: pathname === '/clients' ? '2px' : '1px'
						}}
						transition={{
							duration: 0.2
						}}
					/>
				</span>
			</div>
			<div onClick={() => dispatch(toggleMobileNav())}>
				<span>
					<div>
						<Link to="/contact" style={{ fontWeight: pathname === '/contact' ? 600 : 400 }}>Contact</Link>
					</div>
					<motion.div
						className="line"
						animate={{
							height: pathname === '/contact' ? '2px' : '1px'
						}}
						transition={{
							duration: 0.2
						}}
					/>
				</span>
			</div>
		</motion.nav>

		<div className="mobile-navbar-toggler">
			<motion.div
				initial="visible"
				animate={mobileNav ? 'hidden' : 'visible'}
				variants={iconVariants}
			>
				<AppstoreTwoTone twoToneColor="#90a8b0" onClick={() => dispatch(toggleMobileNav())} />
			</motion.div>
			<motion.div
				initial="hidden"
				animate={mobileNav ? 'visible' : 'hidden'}
				variants={iconVariants}
			>
				<CloseCircleTwoTone twoToneColor="#90a8b0" onClick={() => dispatch(toggleMobileNav())} />
			</motion.div>
		</div>
	</>
);

export default connect(
	state => ({
		service: state.service,
		mobileNav: state.mobileNav

	})
)(MobileNav);
