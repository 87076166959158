import React from 'react';
import { Provider } from 'react-redux';
import Layout from './src/components/Layout';
import store from './src/components/store';

const transitionDelay = 350;

export const wrapPageElement = ({ element, props }) => (
	<Provider store={store}>
		<Layout {...props}>
			{element}
		</Layout>
	</Provider>
);

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	if (location.action === 'PUSH') {
		window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
	}
	else {
		const savedPosition = getSavedScrollPosition(location);
		window.setTimeout(
			() => window.scrollTo(...(savedPosition || [0, 0])),
			transitionDelay
		);
	}

	return false;
};
